<template>
  <div>
    <el-button type="text" @click="chooseIcon">图标选择</el-button>
    <el-dialog title="选择图标" :visible.sync="addIconVisible" append-to-body center>
      <el-divider>可选图标</el-divider>
      <div  id="tooldiv">
      <el-row v-for="(item,index) in rowlength" :key="index">
        <el-col
          v-for="(item1,index1) in collength(index,rowlength)"
          :key="index1"
          :span="parseInt(24/defaultcolLength)"
        >
          <ul>
            <i
              :class="IconList[defaultcolLength*index+index1]"
              @click="selectedIcon(IconList[defaultcolLength*index+index1])"
              style="font-size: 25px;margin-left:50%;"
            ></i>
          </ul>
        </el-col>
      </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "Iconbar",
  data () {
    return {
      addIconVisible: false,
      defaultcolLength: 7,
     IconList: ['el-icon-delete-solid','el-icon-delete','el-icon-s-tools','el-icon-setting','el-icon-user','el-icon-star-off','el-icon-warning-outline','el-icon-remove','el-icon-circle-plus','el-icon-success','el-icon-error','el-icon-upload','el-icon-download','el-icon-refresh','el-icon-edit','el-icon-turn-off','el-icon-open','el-icon-search','el-icon-share','el-icon-edit-outline','el-icon-folder','el-icon-folder-opened'
,'el-icon-folder-add','el-icon-folder-remove','el-icon-folder-delete','el-icon-folder-checked','el-icon-lock','el-icon-unlock','el-icon-more','el-icon-more-outline','el-icon-star-on','el-icon-star-off','el-icon-warning','el-icon-warning-outline','el-icon-question','el-icon-info','el-icon-zoom-in','el-icon-zoom-out','el-icon-remove-outline','el-icon-circle-plus-outline','el-icon-circle-check','el-icon-circle-close',
'el-icon-minus','el-icon-plus','el-icon-check','el-icon-close','el-icon-s-comment','el-icon-s-data','el-icon-s-grid','el-icon-menu','el-icon-d-arrow-left','el-icon-d-arrow-right','el-icon-finished','el-icon-office-building','el-icon-house','el-icon-connection','el-icon-chat-dot-round','el-icon-chat-dot-square','el-icon-chat-line-square','el-icon-time','el-icon-switch-button'] 
    }
  },
  computed: {
    rowlength: function () {
      return this.IconList.length % this.defaultcolLength == 0 ? this.IconList.length / this.defaultcolLength : parseInt(this.IconList.length / this.defaultcolLength) + 1;
    }
  },
  methods: {
    chooseIcon () {
      this.addIconVisible = true;
      let len = this.IconList.length;
    },
    collength: function (index, rowlength) {
      if (index < (rowlength - 1) || this.IconList.length % this.defaultcolLength == 0) {
        return this.defaultcolLength;

      } else {
        return this.IconList.length % this.defaultcolLength;
      }
    },
    selectedIcon (item) {
      this.$emit("callIcon", item); //将值传给父组件
      this.addIconVisible = false;
    }
  }
}
</script>
<style>
#tooldiv{
  overflow-y: auto;
  overflow-x: scroll;
  height: 400px;
  cursor: pointer;
}
</style>