<template>
  <section style="background-color: #ffff">
    <!--顶层工具条-->
    <toolbar
      :buttonList="buttonList"
      @callFunction="callFunction"
      :buttonListmsg="buttonListmsg"
    ></toolbar>
    <!--模块维护工具条-->
    <el-col :span="6">
      <div style="padding-left: 20px; padding-right: 20px">
        <el-row style="height: 40px">
          <ToolbarButtonGroupMini
            :buttonList="moduleButtonList"
            @callFunction="callFunction"
          ></ToolbarButtonGroupMini>
        </el-row>
        <el-divider>模块</el-divider>
        <div class="divtree">
          <el-tree
            :load="moduleLoadChildNode"
            :props="{ label: 'Name', isLeaf: 'Leaf' }"
            :expand-on-click-node="false"
            @node-click="moduleTreeNodeClick"
            lazy
            highlight-current
            :indent="30"
            node-key="Id"
            ref="tree1"
            :data="moduleTreeData"
            :render-content="moduleNodeRenderContent"
          ></el-tree>
        </div>
      </div>
    </el-col>
    <!--菜单维护工具条-->
    <el-col :span="8">
      <div style="padding-left: 20px; padding-right: 20px">
        <el-row style="height: 40px">
          <ToolbarButtonGroupMini
            :buttonList="menuButtonList"
            @callFunction="callFunction"
          ></ToolbarButtonGroupMini>
        </el-row>
        <el-divider>菜单</el-divider>
        <div class="divtree">
          <el-tree
            :data="menuTreeData"
            :load="menuLoadChildNode"
            :props="{ label: 'Name', isLeaf: 'Leaf' }"
            :expand-on-click-node="false"
            lazy
            :indent="30"
            @node-click="menuTreeNodeClick"
            highlight-current
            node-key="Id"
            ref="tree2"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>
                <i v-if="data.Type == 0" class="el-icon-menu" />
                <i v-else class="el-icon-link" />
                <!-- <img v-if="data.Type == 0" src="../../assets/dbtj.png" alt />
                <img v-else src="../../assets/dbtj.png" alt /> -->
                {{ node.label }}
              </span>
            </span>
          </el-tree>
        </div>
      </div>
    </el-col>
    <!--按钮列表-->
    <el-col :span="10">
      <div>
        <el-row style="height: 40px">
          <ToolbarButtonGroupMini
            :buttonList="buttonButtonList"
            @callFunction="callFunction"
          ></ToolbarButtonGroupMini>
        </el-row>
        <el-divider>按钮</el-divider>
        <div class="divtable">
          <el-table
            :data="buttonTableData"
            highlight-current-row
            @current-change="selectCurrentRow"
            style="width: 100%"
            empty-text
          >
            <el-table-column type="index" width="40px"></el-table-column>
            <el-table-column
              label="名称"
              prop="Name"
              width
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="启用"
              prop="Enabled"
              width
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-switch v-model="scope.row.Enabled" disabled></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              label="图标"
              prop="Icon"
              width
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <i class="fa" :class="scope.row.Icon"></i>
                {{ scope.row.Icon }}
              </template>
            </el-table-column>
            <el-table-column
              label="事件"
              prop="Func"
              width
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="描述"
              prop="Description"
              width
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
    <!--模块维护-->
    <el-dialog
      :title="operateType == 0 ? '新增' : '编辑'"
      :visible.sync="moduleDialogVisible"
    >
      <el-form
        :model="moduleForm"
        label-width="85px"
        :rules="addModuleRules"
        ref="moduleForm"
      >
        <el-form-item label="上级:" prop="ParentName" v-show="operateType == 0">
          <el-input v-model="moduleForm.ParentName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="名称:" prop="Name">
          <el-input v-model="moduleForm.Name"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="Type">
          <el-radio v-model="moduleForm.Type" :label="0">单页面应用</el-radio>
          <el-radio v-model="moduleForm.Type" :label="1">带导航应用</el-radio>
          <el-radio v-model="moduleForm.Type" :label="2">外部应用</el-radio>
        </el-form-item>
        <el-form-item label="地址" prop="Url">
          <el-tooltip
            class="item"
            effect="dark"
            content="若是导航条且无路由，请填写‘-’字符"
            placement="top-start"
          >
            <el-input v-model="moduleForm.Url"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="排序:" prop="Sequence">
          <el-input v-model="moduleForm.Sequence" type="number"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="Icon">
          <el-row>
            <el-col :span="20">
              <el-input v-model="moduleForm.Icon" readonly>{{
                moduleForm.Icon
              }}</el-input>
            </el-col>
            <el-col :span="2">
              <iconbar @callIcon="callIcon"></iconbar>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="描述:" prop="Description">
          <el-input v-model="moduleForm.Description" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="启用" prop="Enabled">
          <el-switch v-model="moduleForm.Enabled"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="moduleSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--菜单维护-->
    <el-dialog
      :title="operateType == 0 ? '新增' : '编辑'"
      :visible.sync="menuDialogVisible"
    >
      <el-form
        :model="menuForm"
        label-width="85px"
        :rules="addMenuRules"
        ref="menuForm"
      >
        <el-form-item label="上级:" prop="ParentName" v-show="operateType == 0">
          <el-input v-model="menuForm.ParentName" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="名称:" prop="Name">
          <el-input v-model="menuForm.Name"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="Type">
          <el-radio v-model="menuForm.Type" :label="0">内部菜单</el-radio>
          <el-radio v-model="menuForm.Type" :label="1">外部菜单</el-radio>
        </el-form-item>
        <el-form-item label="地址" prop="Url">
          <el-tooltip
            class="item"
            effect="dark"
            content="若是导航条且无路由，请填写‘-’字符"
            placement="top-start"
          >
            <el-input v-model="menuForm.Url"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="排序:" prop="Sequence">
          <el-input v-model="menuForm.Sequence" type="number"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="Icon">
          <el-row>
            <el-col :span="20">
              <el-input v-model="menuForm.Icon" readonly>{{
                menuForm.Icon
              }}</el-input>
            </el-col>
            <el-col :span="2">
              <iconbar @callIcon="callIcon"></iconbar>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="描述:" prop="Description">
          <el-input v-model="menuForm.Description" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="启用" prop="Enabled">
          <el-switch v-model="menuForm.Enabled"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="menuSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--按钮维护-->
    <el-dialog
      :title="operateType == 0 ? '新增' : '编辑'"
      :visible.sync="buttonDialogVisible"
    >
      <el-form
        :model="buttonForm"
        label-width="85px"
        :rules="addButtonRules"
        ref="buttonForm"
      >
        <el-form-item label="菜单:" prop="MenuName" v-show="operateType == 0">
          <el-input v-model="buttonForm.MenuName" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="名称:" prop="Name">
          <el-input v-model="buttonForm.Name"></el-input>
        </el-form-item>
        <el-form-item label="事件:" prop="Func">
          <el-input v-model="buttonForm.Func"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="Sequence">
          <el-input v-model="buttonForm.Sequence" type="number"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="Icon">
          <el-row>
            <el-col :span="20">
              <el-input v-model="buttonForm.Icon" readonly>{{
                buttonForm.Icon
              }}</el-input>
            </el-col>
            <el-col :span="2">
              <iconbar @callIcon="callIcon"></iconbar>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="描述:" prop="Description">
          <el-input v-model="buttonForm.Description" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="启用" prop="Enabled">
          <el-switch v-model="buttonForm.Enabled"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="buttonSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </section>
</template>
<script>
import {
  ModuleGetNodeChildren,
  ModuleAdd,
  ModuleUpdate,
  MenuGetNodeChildren,
  ModuleDelete,
  MenuAdd,
  MenuUpdate,
  ButtonGetListByMenuId,
  ButtonAdd,
  ButtonUpdate,
  ButtonDelete,
  MenuDelete,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import ToolbarButtonGroupMini from "../../components/ToolbarButtonGroupMini";
import Iconbar from "../../components/Iconbar";
export default {
  components: { Toolbar, ToolbarButtonGroupMini, Iconbar },
  data() {
    return {
      moduleTreeData: [], //模块树绑定数据
      moduleDialogVisible: false, //模块维护弹窗
      operateType: -1, //操作类型
      menuTreeData: [], //菜单树绑定数据
      menuDialogVisible: false, //菜单树维护弹窗
      buttonTableData: [], //按钮列表绑定数据
      currentRow: null, //当前table选中行
      buttonDialogVisible: false, //按钮维护弹窗
      buttonList: [], //通用按钮集合
      moduleButtonList: [], //模块按钮集合
      menuButtonList: [], //菜单按钮集合
      buttonButtonList: [], //按钮按钮集合
      buttonListmsg: "请输入名称查询",
      addModuleRules: {
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Url: [{ required: true, message: "请输入地址", trigger: "blur" }],
        Type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        Sequence: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      addMenuRules: {
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Url: [{ required: true, message: "请输入地址", trigger: "blur" }],
        Type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        Sequence: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      addButtonRules: {
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Sequence: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      //模块
      moduleForm: {
        Id: null,
        Name: null,
        ParentName: null,
        ParentId: null,
        CascadeParentId: null,
        ApplicationId: "39f7cff8-d773-8d46-a740-c552e4165e54",
        Url: null,
        Type: null,
        Icon: "el-icon-menu",
        Description: null,
        Sequence: 0,
        Enabled: true,
      },
      //菜单
      menuForm: {
        Id: null,
        Name: null,
        ParentName: null,
        ParentId: null,
        CascadeParentId: null,
        ModuleId: null,
        Url: null,
        Type: null,
        Icon: "el-icon-s-grid",
        Description: null,
        Sequence: 0,
        Hided: false,
        Enabled: true,
      },
      //按钮
      buttonForm: {
        Id: null,
        Name: null,
        MenuId: null,
        MenuName: null,
        Icon: null,
        Func: null,
        Description: null,
        Sequence: 0,
        Enabled: true,
      },
    };
  },
  methods: {
    //toolbar查询
    callFunction(item) {
      this.filters = {
        name: item.search,
      };
      this[item.Func].apply(this, item);
    },
    //选择图标回调
    callIcon(item) {
      this.moduleForm.Icon = item;
      this.menuForm.Icon = item;
      this.buttonForm.Icon = item;
    },
    //模块加载节点的子节点集合
    moduleLoadChildNode(node, resolve) {
      let para = { appId: global.appId, pid: node.data.Id };
      ModuleGetNodeChildren(para).then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    //模块节点渲染
    moduleNodeRenderContent(h, { node, data, store }) {
      let icon = "";
      if (data.Type == 0) {
        icon = "el-icon-s-platform";
      } else if (data.Type == 1) {
        icon = "el-icon-s-fold";
      } else if (data.Type == 2) {
        icon = "el-icon-c-scale-to-original";
      }
      return (
        <span class="custom-tree-node">
          <i class={icon}>{node.label}</i>
        </span>
      );
    },
    //懒加载刷新节点
    refreshLazyTree(node) {
      //非末级节点清空子节点
      if (!node.isLeaf) {
        var theChildren = node.childNodes;
        theChildren.splice(0, theChildren.length);
      }
      node.loaded = false;
      node.expand();
    },
    //模块树点击
    moduleTreeNodeClick(node) {
      this.buttonTableData = [];
      let mid = this.$refs.tree1.getCurrentKey();
      let para = { mid: mid, pid: null };
      MenuGetNodeChildren(para).then((res) => {
        this.menuTreeData = res.data.response;
        this.$refs.tree2.setCurrentKey(null);
      });
    },
    //模块新增
    addModule() {
      this.moduleDialogVisible = true;
      this.operateType = 0;
      this.$nextTick(() => {
        this.$refs["moduleForm"].resetFields();
        let currentNodeKey = this.$refs.tree1.getCurrentKey();
        if (currentNodeKey) {
          let currentNode = this.$refs.tree1.getNode(currentNodeKey);
          this.moduleForm.ParentId = currentNode.data.Id;
          this.moduleForm.ParentName = currentNode.data.Name;
        }
      });
    },
    //模块维护提交
    moduleSubmit() {
      this.$refs.moduleForm.validate((vaild) => {
        if (vaild) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            let para = Object.assign({}, this.moduleForm);
            if (this.operateType == 0) {
              ModuleAdd(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["moduleForm"].resetFields();
                  this.moduleDialogVisible = false;
                  this.refreshDptTreeNode();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            } else {
              ModuleUpdate(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["moduleForm"].resetFields();
                  this.moduleDialogVisible = false;
                  this.refreshDptTreeNode();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            }
          });
        }
      });
    },
    //模块刷新
    refreshDptTreeNode() {
      //当前节点
      var currentdptid = this.$refs.tree1.getCurrentKey();
      if (currentdptid) {
        var currentnode = this.$refs.tree1.getNode(currentdptid);
        var pdtreeid = currentnode.data.ParentId;
        //新增并且不是叶子节点时
        if (this.operateType == 0 && !currentnode.isLeaf) {
          //不是叶子结点，重新加载当前节点的子节点
          this.refreshLazyTree(currentnode);
        } else {
          //刷新父节点
          //顶级节点或没有父节点
          if (currentnode.level == 1 || pdtreeid == undefined) {
            //全部重新加载
            let para = { appId: global.appId, pid: null };
            ModuleGetNodeChildren(para).then((res) => {
              this.moduleTreeData = res.data.response;
            });
          } else {
            //重新加载当前节点的父节点
            var pdnode = this.$refs.tree1.getNode(pdtreeid);
            this.refreshLazyTree(pdnode);
          }
        }
      } else {
        //全部重新加载
        let para = { appId: global.appId, pid: null };
        ModuleGetNodeChildren(para).then((res) => {
          this.moduleTreeData = res.data.response;
        });
      }
      //清除选择
      this.$refs.tree1.setCurrentKey(null);
    },
    //模块编辑
    editModule() {
      let currentNodeKey = this.$refs.tree1.getCurrentKey();
      if (currentNodeKey) {
        this.moduleDialogVisible = true;
        this.operateType = 1;
        this.$nextTick(() => {
          this.$refs["moduleForm"].resetFields();
          let currentNode = this.$refs.tree1.getNode(currentNodeKey);
          if (currentNode) {
            this.moduleForm = Object.assign({}, currentNode.data);
          }
        });
      }
    },
    //模块删除
    deleteModule() {
      let currentNodeId = this.$refs.tree1.getCurrentKey();
      if (currentNodeId) {
        let currentNode = this.$refs.tree1.getNode(currentNodeId);
        if (currentNode.isLeaf) {
          this.$alert("确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              let para = { id: currentNodeId };
              ModuleDelete(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.refreshDptTreeNode();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            },
          });
        } else {
          this.$alert("存在子模块，不能直接删除!", "提示", {
            confirmButtonText: "确定",
          });
        }
      }
    },
    //菜单加载节点的子节点集合
    menuLoadChildNode(node, resolve) {
      if (0 < node.level && node.isLeaf == false) {
        let para = { mid: node.data.ModuleId, pid: node.data.Id };
        MenuGetNodeChildren(para).then((res) => {
          const roledata = res.data.response;
          resolve(roledata);
        });
      }
    },
    //菜单添加
    addMenu() {
      var moduleId = this.$refs.tree1.getCurrentKey();
      if (moduleId) {
        this.menuDialogVisible = true;
        this.operateType = 0;
        this.$nextTick(() => {
          this.$refs["menuForm"].resetFields();
          let currentMenuId = this.$refs.tree2.getCurrentKey();
          console.log(currentMenuId);
          if (currentMenuId) {
            let currentNode = this.$refs.tree2.getNode(currentMenuId);
            this.menuForm.ParentId = currentNode.data.Id;
            this.menuForm.ParentName = currentNode.data.Name;
            this.menuForm.ModuleId = currentNode.data.ModuleId;
          } else {
            this.menuForm.ModuleId = moduleId;
            this.menuForm.ParentId = null;
            this.menuForm.ParentName = null;
          }
        });
      }
    },
    //菜单维护提交
    menuSubmit() {
      this.$refs.menuForm.validate((vaild) => {
        if (vaild) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            let para = Object.assign({}, this.menuForm);
            if (this.operateType == 0) {
              MenuAdd(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["menuForm"].resetFields();
                  this.menuDialogVisible = false;
                  this.refreshMenuTreeNode();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            } else {
              MenuUpdate(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["menuForm"].resetFields();
                  this.menuDialogVisible = false;
                  this.refreshMenuTreeNode();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            }
          });
        }
      });
    },
    //菜单刷新
    refreshMenuTreeNode() {
      let moduleId = this.$refs.tree1.getCurrentKey();
      if (moduleId) {
        //当前节点
        var currentid = this.$refs.tree2.getCurrentKey();
        if (currentid) {
          var currentnode = this.$refs.tree2.getNode(currentid);
          var treepid = currentnode.data.ParentId;
          //新增并且不是叶子节点时
          if (this.operateType == 0 && !currentnode.isLeaf) {
            //不是叶子结点，重新加载当前节点的子节点
            this.refreshLazyTree(currentnode);
          } else {
            //刷新父节点
            //顶级节点或没有父节点
            if (currentnode.level == 1 || treepid == undefined) {
              //全部重新加载
              let para = { mid: moduleId, pid: null };
              MenuGetNodeChildren(para).then((res) => {
                this.menuTreeData = res.data.response;
              });
            } else {
              //重新加载当前节点的父节点
              var pdnode = this.$refs.tree2.getNode(treepid);
              this.refreshLazyTree(pdnode);
            }
          }
        } else {
          //全部重新加载
          let para = { mid: moduleId, pid: null };
          MenuGetNodeChildren(para).then((res) => {
            this.menuTreeData = res.data.response;
          });
        }
        //清除选择
        this.$refs.tree2.setCurrentKey(null);
      }
    },
    //菜单编辑
    editMenu() {
      let currentNodeKey = this.$refs.tree2.getCurrentKey();
      if (currentNodeKey) {
        this.menuDialogVisible = true;
        this.operateType = 1;
        this.$nextTick(() => {
          this.$refs["menuForm"].resetFields();
          let currentNode = this.$refs.tree2.getNode(currentNodeKey);
          if (currentNode) {
            this.menuForm = Object.assign({}, currentNode.data);
          }
        });
      }
    },
    //菜单删除
    deleteMenu() {
      let currentPostId = this.$refs.tree2.getCurrentKey();
      if (currentPostId) {
        let currentNode = this.$refs.tree2.getNode(currentPostId);
        if (currentNode.isLeaf) {
          this.$alert("确定要删除吗？", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              let para = { id: currentPostId };
              MenuDelete(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.refreshMenuTreeNode();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            },
          });
        } else {
          this.$alert("存在子菜单，不能直接删除!", "提示", {
            confirmButtonText: "确定",
          });
        }
      }
    },
    //菜单树节点点击
    menuTreeNodeClick(node) {
      console.log(node);
      if (node.Id) {
        this.GetButtonListByMenuId(node.Id);
      }
    },
    //获取按钮列表
    GetButtonListByMenuId(mid) {
      let para = { mid: mid };
      ButtonGetListByMenuId(para).then((res) => {
        this.buttonTableData = res.data.response;
      });
    },
    //绑定选中row信息
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    //按钮添加
    addButton() {
      let menuId = this.$refs.tree2.getCurrentKey();
      let currentNode = this.$refs.tree2.getNode(menuId);
      console.log(currentNode);
      if (menuId && currentNode) {
        this.buttonDialogVisible = true;
        this.operateType = 0;
        this.$nextTick(() => {
          this.$refs["buttonForm"].resetFields();
          this.buttonForm.MenuId = menuId;
          this.buttonForm.MenuName = currentNode.data.Name;
        });
      }
    },
    //按钮维护提交
    buttonSubmit() {
      this.$refs.buttonForm.validate((vaild) => {
        if (vaild) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            let para = Object.assign({}, this.buttonForm);
            if (this.operateType == 0) {
              ButtonAdd(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["buttonForm"].resetFields();
                  this.buttonDialogVisible = false;
                  this.refreshButtonList();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            } else {
              ButtonUpdate(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["buttonForm"].resetFields();
                  this.buttonDialogVisible = false;
                  this.refreshButtonList();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            }
          });
        }
      });
    },
    //按钮列表刷新
    refreshButtonList() {
      let menuId = this.$refs.tree2.getCurrentKey();
      if (menuId) {
        this.GetButtonListByMenuId(menuId);
      }
    },
    //按钮编辑
    editButton() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.buttonDialogVisible = true;
      this.operateType = 1;
      this.$nextTick(() => {
        this.$refs["buttonForm"].resetFields();
        this.buttonForm = Object.assign({}, row);
      });
    },
    //按钮删除
    deleteButton() {
      let row = this.currentRow;
      let para = { id: row.Id };
      this.$alert("是否删除!", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            ButtonDelete(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.refreshButtonList();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          }
        },
      });
    },
  },
  mounted() {
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];

    var allButtons = getButtonList(this.$route.path, routers, buttons);
    allButtons.forEach((element) => {
      if (
        element.Func &&
        (element.Func.toLowerCase() == "addModule".toLowerCase() ||
          element.Func.toLowerCase() == "editModule".toLowerCase() ||
          element.Func.toLowerCase() == "deleteModule".toLowerCase())
      ) {
        this.moduleButtonList.push(element);
      } else if (
        element.Func &&
        (element.Func.toLowerCase() == "addMenu".toLowerCase() ||
          element.Func.toLowerCase() == "editMenu".toLowerCase() ||
          element.Func.toLowerCase() == "deleteMenu".toLowerCase())
      ) {
        this.menuButtonList.push(element);
      } else if (
        element.Func &&
        (element.Func.toLowerCase() == "addButton".toLowerCase() ||
          element.Func.toLowerCase() == "editButton".toLowerCase() ||
          element.Func.toLowerCase() == "deleteButton".toLowerCase())
      ) {
        this.buttonButtonList.push(element);
      } else {
        this.buttonList.push(element);
      }
    });
  },
};
</script>
<style scoped>
.el-col
  >>> .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #fabe64;
}

.el-col >>> .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.el-col >>> .el-icon-caret-right:before {
  content: url("../../assets/add.png") !important;
  font-size: 16px;
}
.el-col >>> .expanded:before {
  content: url("../../assets/cancel.png") !important;
  font-size: 16px;
}
.el-col >>> .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: url("../../assets/blank.png") !important;
  font-size: 16px;
}

.el-form-item .el-input {
  width: 100%;
}

.el-form-item .el-select {
  width: 100%;
}

.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}
.divtree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 110px);
  /*width:200px;*/
  border: 0px solid blue;
}
.divtree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 130px);
  /*width:200px;*/
  border: 0px solid blue;
}
.container .main {
  background-color: #ffffff !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* position: absolute; */
  top: 60px;
  bottom: 0px;
  overflow: hidden;
}
</style>